import regexUtils from 'optly/utils/regex';

/**
 * @description Character length enforced by the backend for all keys
 */
const MAX_EVENT_KEY_CHARACTER_LENGTH = 64;

const UNIQUE_ID_WITH_DELIMITER_CHARACTER_LENGTH = 6;

/* eslint-disable no-bitwise */
/**
 * @description Adds a short unique key to end of the provided string(s)
 * @example
 *  generateOrConcatenateUniqueId() => "6f6s2"
 *  generateOrConcatenateUniqueId(['_']) => "_6f6s2"
 *  generateOrConcatenateUniqueId(['my_event_key', '_']) => "my_event_key_6f6s2"
 */
export const generateOrConcatenateUniqueId = (valuesToConcatenate = []) => {
  const fiveCharacterUniqueId = 'xfxsx'
    .replace(/[x]/g, c => {
      const r = (Math.random() * 16) | 0;
      const v = c === 'x' ? r : (r & 0x3) | 0x8;
      return v.toString(16);
    })
    .toLowerCase();
  return valuesToConcatenate.join('') + fiveCharacterUniqueId;
};
/* eslint-enable no-bitwise */

/**
 * @description Given a string returns, replaces all disallowed characters with underscores ("_")
 */
export const getFormattedEventAPIName = apiName => {
  let formattedEventAPIName = apiName
    .replace(regexUtils.eventAPINameDisallowedChars, '_')
    .toLowerCase();

  if (formattedEventAPIName.length >= MAX_EVENT_KEY_CHARACTER_LENGTH) {
    formattedEventAPIName = generateOrConcatenateUniqueId([
      formattedEventAPIName.slice(
        0,
        MAX_EVENT_KEY_CHARACTER_LENGTH -
          UNIQUE_ID_WITH_DELIMITER_CHARACTER_LENGTH,
      ),
      '_',
    ]);
  }

  return formattedEventAPIName;
};

export default {
  getFormattedEventAPIName,
};
